import React, { useState } from "react";
import { Row, Col, Pagination } from "react-bootstrap";

import "./PaginationBlock.scss";

export default function PaginationBlock({ lastPage, refresh }) {
  const [activePage, setActivePage] = useState(1);

  const pageRangeDisplayed = 4;
  const showLowerEllipsis = activePage - pageRangeDisplayed > 1;
  const paginationStart = showLowerEllipsis
    ? activePage - pageRangeDisplayed
    : 1;
  const showUpperEllipsis = paginationStart + pageRangeDisplayed < lastPage;
  const paginationEnd = showUpperEllipsis
    ? paginationStart + pageRangeDisplayed
    : lastPage;

  let paginationItems = [];
  if (showLowerEllipsis) {
    paginationItems.push(<Pagination.Ellipsis key='first' />);
  }
  for (let number = paginationStart; number <= paginationEnd; number++) {
    paginationItems.push(
      <Pagination.Item
        key={number}
        active={number === activePage}
        onClick={(e) => {
          const index = number;
          if (index !== activePage) {
            setActivePage(index);
            refresh(index);
          }
        }}>
        {number}
      </Pagination.Item>
    );
  }
  if (showUpperEllipsis) {
    paginationItems.push(<Pagination.Ellipsis key='last' />);
  }
  return (
    <Row className='pagination-block-row'>
      <Col className='pagination-block-col'>
        <Pagination>
          <Pagination.First
            onClick={(e) => {
              const index = 1;
              if (index !== activePage) {
                setActivePage(index);
                refresh(index);
              }
            }}
          />
          <Pagination.Prev
            onClick={(e) => {
              const index = activePage > 1 ? activePage - 1 : 1;
              if (index !== activePage) {
                setActivePage(index);
                refresh(index);
              }
            }}
          />
          {paginationItems}
          <Pagination.Next
            onClick={(e) => {
              const index = activePage < lastPage ? activePage + 1 : lastPage;
              if (index !== activePage) {
                setActivePage(index);
                refresh(index);
              }
            }}
          />
          <Pagination.Last
            onClick={(e) => {
              const index = lastPage;
              if (index !== activePage) {
                setActivePage(index);
                refresh(index);
              }
            }}
          />
        </Pagination>
      </Col>
    </Row>
  );
}
