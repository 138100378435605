import React, { useState } from "react";
import { Row, Col, Button, Form, Modal } from "react-bootstrap";
import axios from "axios";

export default function AccountEditUserPopup({
  accountId,
  user,
  closePopup,
  submitUpdate,
}) {
  const [state, setState] = useState({
    email: user.email ? user.email : "",
    read: user.roles ? user.roles.includes("role.read") : true,
    write: user.roles ? user.roles.includes("role.write") : true,
    admin: user.roles ? user.roles.includes("role.admin") : true,
    access: user.roles ? user.roles.includes("role.access") : true,
  });

  const updateRole = (id) => {
    switch (id) {
      case "read":
        if (state.read) {
          setState({
            ...state,
            read: false,
            admin: false,
          });
        } else {
          setState({
            ...state,
            read: true,
            write: false,
            admin: false,
          });
        }
        break;
      case "write":
        if (state.write) {
          setState({
            ...state,
            write: false,
            admin: false,
          });
        } else {
          setState({
            ...state,
            write: true,
            read: false,
            admin: false,
          });
        }
        break;
      case "admin":
        setState({
          ...state,
          admin: !state.admin,
          read: !state.admin,
          write: !state.admin,
          access: !state.admin,
        });
        break;
      case "access":
        if (state.access) {
          setState({
            ...state,
            access: false,
            admin: false,
          });
        } else {
          setState({
            ...state,
            access: true,
            admin: false,
          });
        }
        break;
      default:
        setState({
          ...state,
          read: false,
          write: false,
          access: false,
          admin: false,
        });
    }
  };

  return (
    <Modal
      show={true}
      onHide={closePopup}
      aria-labelledby='contained-modal-title-vcenter'
      centered>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          submitUpdate(async () => {
            const email = state.email;
            const roles = [
              state.read && "role.read",
              state.write && "role.write",
              state.admin && "role.admin",
              state.access && "role.access",
            ];
            const newUser = {
              email,
              roles,
            };
            user.email
              ? await axios.put(
                  `/api/admin/accounts/${accountId}/users/${newUser.email}`,
                  newUser
                )
              : await axios.post(
                  `/api/admin/accounts/${accountId}/users`,
                  newUser
                );
            closePopup();
          });
        }}
        onReset={closePopup}>
        <Modal.Header closeButton>
          <Modal.Title>
            {user.email
              ? `Modifier l'utilisateur ${user.email}`
              : "Ajout d'un utilisateur"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId='formUserAdd'>
            <Form.Label className='title'>Email</Form.Label>
            <Form.Control
              type='text'
              className='from-number'
              placeholder='Adresse email'
              value={state.email}
              onChange={(e) => {
                setState({
                  ...state,
                  email: e.target.value,
                });
              }}
              pattern='^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$'
              required
              disabled={user.email}
            />
          </Form.Group>
          <Form.Group controlId='formUserAdd'>
            <Form.Label className='title'>Permissions</Form.Label>
            <Row>
              <Col>
                <Form.Check
                  type={"checkbox"}
                  id={`role.admin`}
                  label={`Gestion du compte`}
                  checked={state.admin}
                  onChange={(e) => updateRole("admin")}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Check
                  type={"checkbox"}
                  id={`role.write`}
                  label={`Écriture`}
                  checked={state.write}
                  onChange={(e) => updateRole("write")}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Check
                  type={"checkbox"}
                  id={`read`}
                  label={`Lecture`}
                  checked={state.read}
                  onChange={(e) => updateRole("read")}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Check
                  type={"checkbox"}
                  id={`role.access`}
                  label={`Gestion des accès`}
                  checked={state.access}
                  onChange={(e) => updateRole("access")}
                />
              </Col>
            </Row>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='success' type='submit'>
            Sauvegarder
          </Button>
          <Button variant='light' type='reset'>
            Annuler
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
