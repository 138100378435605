import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import { Bar } from "react-chartjs-2";

export default function Stats() {
  const [state, setState] = useState({
    info: {
      plans: [],
    },
  });

  useEffect(() => {
    fetch("/api/admin/accounts/info")
      .then((res) => res.json())
      .then((data) => {
        setState({
          info: data,
        });
      });
  }, []);

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Nombre de comptes clients actifs par plan",
      },
    },
  };

  const labels = ["Plans"];
  const colors = {
    "plan.start": "#7D98C5",
    "plan.entreprise": "#00B0F0",
    "plan.pro": "#3E7A97",
    "plan.premium": "#72CC51",
    "plan.createur": "#F87869",
    "plan.export": "#1FBAB5",
  };

  const data = {
    labels,
    datasets: state.info.plans.map((plan, index) => {
      return {
        label: plan.label,
        data: [plan.count],
        backgroundColor: colors[plan.id] ? colors[plan.id] : "#009900",
      };
    }),
  };

  return (
    <Container className='accounts'>
      <Row>
        <Col>
          <h2>Statistiques</h2>
        </Col>
      </Row>
      <Row>
        <Col xs={5}>Total de comptes</Col>
        <Col xs={1}>{state.info.total}</Col>
      </Row>
      <Row>
        <Col xs={5}>Comptes clients actifs</Col>
        <Col xs={1}>{state.info.totalWithSubId}</Col>
      </Row>
      <Row>
        <Col xs={5}>Comptes clients désactivés</Col>
        <Col xs={1}>{state.info.totalWithSubIdDisable}</Col>
      </Row>
      <Row>
        <Col xs={5}>Comptes internes actifs (Manageo + Altares)</Col>
        <Col xs={1}>{state.info.totalWithoutSubId}</Col>
      </Row>
      <Row>
        <Col xs={5}>Comptes internes désactivés (Manageo + Altares)</Col>
        <Col xs={1}>{state.info.totalWithoutSubIdDisable}</Col>
      </Row>
      <Row>
        <Col xs={5}>Comptes sans plan</Col>
        <Col xs={1}>{state.info.plan}</Col>
      </Row>{" "}
      <Row>
        <Col xs={5}>Comptes clients actifs multi-user</Col>
        <Col xs={1}>{state.info.totalMultiUser}</Col>
      </Row>
      <Bar options={options} data={data} />
    </Container>
  );
}
