import React from "react";
import { Button, Row, Col, Container } from "react-bootstrap";

export default function ReloadBlock({ callBack }) {
  return (
    <>
      <Container>
        <Row className='justify-content-md-center'>
          <Col>
            <Button size='sm' variant='info' onClick={callBack}>
              Recharger
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
}
