import React, { useContext, useEffect, useState } from "react";
import "./App.scss";
import { HashRouter as Router, Switch, Route, NavLink } from "react-router-dom";
import { Row, Col, Alert } from "react-bootstrap";
import { ErrorContext } from "./context/ErrorContext";
import axios from "axios";

import Account from "./Account/Account";
import AccountCreate from "./Account/AccountCreate";

import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import GroupAddIcon from "@mui/icons-material/GroupAdd";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import CodeIcon from "@mui/icons-material/Code";
import CodeOffIcon from "@mui/icons-material/CodeOff";
import SettingsIcon from "@mui/icons-material/Settings";

import Accounts from "./Accounts/Accounts";
import Stats from "./Admin/Stats";
import Admin from "./Admin/Admin";
import Features from "./Admin/Features";
import Plans from "./Admin/Plans";

import logo from "./assets/images/logo-mlist.png";
import logoM from "./assets/images/logo-manageo.png";

const axiosErrorContext = {};

const TIMEOUT = 1 * 60 * 1000;
axios.defaults.timeout = TIMEOUT;
axios.defaults.baseURL = process.env.SERVER_API_URL;

const setupAxiosInterceptors = () => {
  const onRequestSuccess = (config) => {
    return config;
  };
  const onResponseSuccess = (response) => {
    if (axiosErrorContext.error) {
      axiosErrorContext.setError(false);
    }
    return response;
  };
  const onResponseError = (err) => {
    const status = err.status || (err.response ? err.response.status : 0);
    if (status && status >= 400) {
      //Showing error bar
      axiosErrorContext.setError(true);
    } else {
      window.location = "/oauth2/authorization/oidc";
    }

    return Promise.reject(err);
  };
  axios.interceptors.request.use(onRequestSuccess);
  axios.interceptors.response.use(onResponseSuccess, onResponseError);
};

setupAxiosInterceptors();

const App = () => {
  const [error, setError] = useContext(ErrorContext);
  const [roles, setRoles] = useState([]);
  const [open, setOpen] = useState(false);

  const theme = useTheme();

  useEffect(() => {
    axios
      .get("/api/admin/accounts/roles")
      .then((response) => setRoles(response.data));
  }, []);

  const drawerWidth = 240;

  const openedMixin = (theme) => ({
    width: drawerWidth,
    backgroundColor: "#37444E",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
  });

  const closedMixin = (theme) => ({
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: "#37444E",
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
      width: `calc(${theme.spacing(9)} + 1px)`,
    },
  });

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "#7E97C5",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

  const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
  })(
    {
      backgroundColor: "#37444E",
    },
    ({ theme, open }) => ({
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap",
      boxSizing: "border-box",
      ...(open && {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
      }),
      ...(!open && {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
      }),
    })
  );
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const MyGroupAddIcon = styled(GroupAddIcon)({
    color: "white",
  });

  const CenteredTypography = styled(Typography)({
    position: "fixed",
    top: "5",
    left: "40%",
  });

  const MyListItem = styled(ListItem)({
    color: "white",
  });
  const MyQueryStatsIcon = styled(QueryStatsIcon)({
    color: "white",
  });
  const MyCodeIcon = styled(CodeIcon)({
    color: "white",
  });
  const MyCodeOffIcon = styled(CodeOffIcon)({
    color: "white",
  });
  const MySettingsIcon = styled(SettingsIcon)({
    color: "white",
  });

  axiosErrorContext.error = error;
  axiosErrorContext.setError = setError;

  const errorToast = error ? (
    <Row className='justify-content-md-center'>
      <Col md='auto'>
        <Alert
          show={error}
          variant='danger'
          onClose={() => setError(false)}
          dismissible>
          <p>Une erreur est survenue.</p>
        </Alert>
      </Col>
    </Row>
  ) : null;

  const routeCreate = roles.includes("ROLE_EDIT") ? (
    <Route exact path='/create' component={AccountCreate} />
  ) : null;

  setTimeout(() => setError(false), 2000);

  return (
    <Router>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position='fixed' open={open}>
          <Toolbar>
            <IconButton
              color='inherit'
              aria-label='open drawer'
              onClick={handleDrawerOpen}
              edge='start'
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" }),
              }}>
              <MenuIcon />
            </IconButton>
            <CenteredTypography variant='h6' noWrap component='div'>
              Admin des ventes{" "}
              <a href='/'>
                <img src={logo} alt='logo' />
              </a>
            </CenteredTypography>

            <a
              href='/'
              style={{
                position: "absolute",
                right: "0",
                marginRight: "20px",
              }}>
              <img src={logoM} alt='logo manageo' />
            </a>
          </Toolbar>
        </AppBar>
        <Drawer variant='permanent' open={open}>
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List>
            <NavLink to='/'>
              <MyListItem>
                <ListItemIcon>
                  <MyGroupAddIcon />
                </ListItemIcon>
                <ListItemText primary='Comptes' />
              </MyListItem>
            </NavLink>
            {roles.includes("ROLE_EDIT") && (
              <NavLink to='/stats'>
                <MyListItem>
                  <ListItemIcon>
                    <MyQueryStatsIcon />
                  </ListItemIcon>
                  <ListItemText primary='Statistiques' />
                </MyListItem>
              </NavLink>
            )}
            {roles.includes("ROLE_EDIT") && (
              <NavLink to='/features'>
                <MyListItem>
                  <ListItemIcon>
                    <MyCodeIcon />
                  </ListItemIcon>
                  <ListItemText primary='Fonctionnalités' />
                </MyListItem>
              </NavLink>
            )}
            {roles.includes("ROLE_EDIT") && (
              <NavLink to='/plans'>
                <MyListItem>
                  <ListItemIcon>
                    <MyCodeOffIcon />
                  </ListItemIcon>
                  <ListItemText primary='Plans' />
                </MyListItem>
              </NavLink>
            )}
            {roles.includes("ROLE_EDIT") && (
              <NavLink to='/admin'>
                <MyListItem>
                  <ListItemIcon>
                    <MySettingsIcon />
                  </ListItemIcon>
                  <ListItemText primary='Administration' />
                </MyListItem>
              </NavLink>
            )}
          </List>
        </Drawer>
        <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
          <DrawerHeader />
          {errorToast}

          <Switch>
            <Route
              exact
              path='/'
              render={(props) => (
                <Accounts canWrite={roles.includes("ROLE_EDIT")} {...props} />
              )}
            />
            <Route exact path='/stats' component={Stats} />
            <Route exact path='/features' component={Features} />
            <Route exact path='/plans' component={Plans} />
            <Route exact path='/admin' component={Admin} />
            {routeCreate}
            {roles.length && (
              <Route
                path='/accounts/:accountId/'
                render={(props) => (
                  <Account
                    canWrite={roles.includes("ROLE_EDIT")}
                    canManageUsers={roles.includes("ROLE_MANAGE_USERS")}
                    canRead={
                      roles.includes("ROLE_EDIT") || roles.includes("ROLE_READ")
                    }
                    {...props}
                  />
                )}
              />
            )}
          </Switch>
        </Box>
      </Box>
    </Router>
  );
};

export default App;
