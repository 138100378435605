import React, { useState, useEffect } from "react";
import _ from "lodash";
import axios from "axios";
import DatePicker from "react-datepicker";
import moment from "moment";
import {
  Container,
  Row,
  Col,
  InputGroup,
  FormControl,
  Table,
} from "react-bootstrap";
import PaginationBlock from "../tools/PaginationBlock";
import "react-datepicker/dist/react-datepicker.css";
import { styled } from "@mui/material/styles";

export default function AccountChange({ accountId }) {
  const [refresh, setRefresh] = useState(true);
  const [startDate, setStartDate] = useState(
    moment().subtract(1, "months").toDate()
  );
  const [endDate, setEndDate] = useState(new Date());
  const [changes, setChanges] = useState(null);
  const [countPerPage] = useState(15);
  const [activePage, setActivePage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [userSearch, setUserSearch] = useState("");

  const MyTable = styled(Table)({
    fontSize: "14px",
  });

  useEffect(() => {
    const start = moment(startDate).format("YYYYMMDD");
    const end = moment(endDate).format("YYYYMMDD");
    const requestName = userSearch ? `&search=${userSearch}` : "";
    axios
      .get(
        `/api/admin/accounts/${accountId}/change?startDate=${start}&endDate=${end}&max-results=${countPerPage}&start-index=${
          activePage - 1
        }${requestName}`
      )
      .then((response) => {
        setLastPage(Math.ceil(response.data.count / countPerPage));
        setChanges(response.data.changes);
      });
  }, [
    accountId,
    startDate,
    endDate,
    refresh,
    userSearch,
    activePage,
    countPerPage,
  ]);

  const rows = _.map(changes, (change, key) => {
    return (
      <tr key={key}>
        <td>{change.changeDate}</td>
        <td>{change.changeBy}</td>
        <td>{change.message}</td>
      </tr>
    );
  });

  return (
    <Container className='change'>
      <Row>
        <Col xs={1}>Depuis</Col>
        <Col xs={1}>
          <DatePicker
            selected={startDate}
            dateFormat='dd/MM/yyyy'
            onChange={(date) => {
              setStartDate(date);
            }}
          />
        </Col>
        <Col xs={1}>Jusqu'à</Col>
        <Col xs={1}>
          <DatePicker
            selected={endDate}
            dateFormat='dd/MM/yyyy'
            onChange={(date) => {
              setEndDate(date);
            }}
          />
        </Col>
        <Col xs={{ span: 4, offset: 4 }}>
          <InputGroup className='mb-3'>
            <InputGroup.Prepend>
              <InputGroup.Text id='basic-addon1'>
                <span className='fas fa-search'></span>
              </InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              placeholder='Recherche ...'
              aria-label='search'
              aria-describedby='basic-addon1'
              value={userSearch}
              onChange={(e) => {
                setUserSearch(e.target.value);
                setActivePage(1);
              }}
            />
            <InputGroup.Append className='clear'>
              <InputGroup.Text id='basic-addon1'>
                <span
                  className='fas fa-times-circle'
                  onClick={() => {
                    setUserSearch("");
                    setActivePage(1);
                  }}></span>
              </InputGroup.Text>
            </InputGroup.Append>
          </InputGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <MyTable striped bordered hover className='changes'>
            <thead>
              <tr>
                <th className='date'>Date et heure</th>
                <th className='changeBy'>Utilisateur</th>
                <th className='message'>Événement</th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </MyTable>
        </Col>
      </Row>
      <PaginationBlock
        lastPage={lastPage}
        refresh={(index) => {
          setActivePage(index);
          setRefresh(!refresh);
        }}
      />
    </Container>
  );
}
