import React from "react";
import { Row, Col, Modal, Spinner } from "react-bootstrap";

export default function LoadingBlock({ applyAction, closeModal }) {
  if (applyAction) {
    applyAction();
  }
  if (!closeModal) {
    return null;
  }
  setTimeout(closeModal, 3000);
  return (
    <>
      <Modal
        show={true}
        onHide={() => {}}
        aria-labelledby='contained-modal-title-vcenter'
        centered>
        <Modal.Body>
          <Row>
            <Col xs={3} style={{ paddingLeft: "50px" }}>
              <Spinner animation='border' role='status'>
                <span className='sr-only'>Loading...</span>
              </Spinner>
            </Col>
            <Col xs={8} style={{ paddingTop: "7px" }}>
              Traitement en cours
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}
