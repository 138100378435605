import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import axios from "axios";

import "./Admin.scss";

export default function Plans() {
  const [state, setState] = useState({
    plan: null,
    plans: [],
    thematics: [],
    collapsed: [],
  });

  useEffect(() => {
    (async () => {
      const { data } = await axios.get(`/api/admin/plans/info`);
      setState({
        plan: data.plans[0],
        thematics: data.thematics,
        plans: data.plans,
        collapsed: data.thematics.map((thematic) => thematic.id),
      });
    })();
  }, []);

  return (
    state.plan && (
      <Container className='admin'>
        <Row>
          <h2>Plan</h2>
        </Row>
        <Row>
          {state.plans.map((plan, key) => (
            <Col key={key}>
              <Button
                size='sm'
                className='button-plan'
                onClick={() => setState({ ...state, plan })}>
                {plan.label}
              </Button>
            </Col>
          ))}
        </Row>
        {state.thematics.map((thematic, key) => {
          const currentGroupIndex = key * 1000;
          const rows = [];
          rows.push(
            <Row key={currentGroupIndex}>
              <Col
                onClick={() => {
                  if (state.collapsed.includes(thematic.id)) {
                    setState({
                      ...state,
                      collapsed: state.collapsed.filter(
                        (c) => c !== thematic.id
                      ),
                    });
                  } else {
                    setState({
                      ...state,
                      collapsed: [...state.collapsed, thematic.id],
                    });
                  }
                }}>
                <i
                  className={
                    state.collapsed.includes(thematic.id)
                      ? "fas fa-plus"
                      : "fas fa-minus"
                  }
                  style={{ paddingRight: "10px" }}
                />
                {thematic.label}
              </Col>
            </Row>
          );
          if (!state.collapsed.includes(thematic.id)) {
            const planFeatures = state.plan.features.map((f) => f.id);
            thematic.groups
              .filter((group) => planFeatures.includes(group.id))
              .forEach((feature, keyFeat) =>
                rows.push(
                  <Row key={currentGroupIndex + keyFeat + 1}>
                    <Col style={{ paddingLeft: "50px" }}>
                      <i>{feature.id}</i> : {feature.label}
                    </Col>
                  </Row>
                )
              );
          }
          return rows;
        })}
      </Container>
    )
  );
}
