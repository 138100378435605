import React, { useState } from "react";
import axios from "axios";
import { Row, Col, Button, Modal, Container } from "react-bootstrap";

import AccountCreateForm from "./AccountCreateForm";
import LoadingBlock from "../tools/LoadingBlock";

import "./Account.scss";

export default function AccountSettings({ account, refreshAccount }) {
  const [showDeleteAccount, setShowDeleteAccount] = useState(false);
  const [showLoadingBlock, setShowLoadingBlock] = useState(false);
  const [loadingCallBack, setLoadingCallBack] = useState(null);

  if (!account) {
    return null;
  }

  const accountForm = (
    <AccountCreateForm
      actionSubmit={(accountModify) => {
        setLoadingCallBack(() => {
          axios.put(`/api/admin/accounts/${account.id}`, accountModify);
        });
        setShowLoadingBlock(true);
      }}
      accountToModify={account}
      showAddCredit={false}
      dropAccountAction={() => {
        setShowDeleteAccount(true);
      }}
    />
  );

  const modalLoading = showLoadingBlock ? (
    <LoadingBlock
      applyAction={loadingCallBack}
      closeModal={() => {
        setShowLoadingBlock(false);
        refreshAccount();
      }}
    />
  ) : null;
  return (
    <>
      {modalLoading}
      {accountForm}
      <Modal
        show={showDeleteAccount}
        onHide={() => {
          setShowDeleteAccount(false);
        }}
        aria-labelledby='contained-modal-title-vcenter'
        centered>
        <Modal.Body>
          <Container>
            <Row>
              <Col>
                Etes vous sur de vouloir supprimer le compte{" "}
                {account ? account.name : null} ?
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='info'
            onClick={() => {
              axios.delete(`/api/admin/accounts/${account.id}`).then(() => {
                window.location = "/";
              });
            }}>
            Confirmer
          </Button>
          <Button
            variant='light'
            onClick={() => {
              setShowDeleteAccount(false);
            }}>
            Annuler
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
