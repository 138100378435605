import React, { useState, useEffect } from "react";
import _ from "lodash";
import axios from "axios";
import DatePicker from "react-datepicker";
import moment from "moment";
import NumberFormat from "react-number-format";
import { Container, Row, Col } from "react-bootstrap";

import "react-datepicker/dist/react-datepicker.css";

export default function AccountUsage({ accountId }) {
  const [startDate, setStartDate] = useState(
    moment().subtract(1, "months").toDate()
  );
  const [endDate, setEndDate] = useState(new Date());
  const [usage, setUsage] = useState(null);

  useEffect(() => {
    const start = moment(startDate).format("YYYYMMDD");
    const end = moment(endDate).format("YYYYMMDD");
    axios
      .get(
        `/api/admin/accounts/${accountId}/usage?startDate=${start}&endDate=${end}`
      )
      .then((response) => {
        setUsage(response.data);
      });
  }, [accountId, startDate, endDate]);

  const packRow = usage
    ? _.map(usage.export.counter, (value, key) => {
        return (
          <Row key={key}>
            <Col className='title' xs={5}>
              {key}
            </Col>
            <Col>{value}</Col>
          </Row>
        );
      })
    : null;

  return (
    <Container className='usage'>
      <Row className='justify-content-md-right'>
        <Col xs={{ span: 1, offset: 6 }}>Depuis</Col>
        <Col xs={2}>
          <DatePicker
            selected={startDate}
            dateFormat='dd/MM/yyyy'
            onChange={(date) => {
              setStartDate(date);
            }}
          />
        </Col>
        <Col xs={1}>Jusqu'à</Col>
        <Col xs={1}>
          <DatePicker
            selected={endDate}
            dateFormat='dd/MM/yyyy'
            onChange={(date) => {
              setEndDate(date);
            }}
          />
        </Col>
      </Row>

      <Row>
        <Col className='title' xs={5}>
          Exports réalisés
        </Col>
        <Col className='value'>{usage ? usage.export.exportFile : null}</Col>
      </Row>
      <Row>
        <Col className='title' xs={5}>
          Ciblages créés
        </Col>
        <Col className='value'>{usage ? usage.targeting : null}</Col>
      </Row>
      <Row>
        <Col className='title' xs={5}>
          Pré-filtres créés
        </Col>
        <Col className='value'>{usage ? usage.preFilter : null}</Col>
      </Row>
      <Row>
        <Col className='title' xs={5}>
          Filtres perso. créés
        </Col>
        <Col className='value'>{usage ? usage.customFilter : null}</Col>
      </Row>
      <Row>
        <Col className='title' xs={5}>
          Crédits
        </Col>
        <Col className='value'>
          {usage ? (
            <NumberFormat
              value={usage.credit}
              displayType={"text"}
              thousandSeparator=' '
            />
          ) : null}
        </Col>
      </Row>
      {packRow}
    </Container>
  );
}
