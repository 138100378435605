import React, { useState, useEffect } from "react";
import { Container, Row, Col, Table } from "react-bootstrap";
import axios from "axios";

import "./Admin.scss";

export default function Features() {
  const [state, setState] = useState({
    thematics: [],
    roles: [],
    collapsed: [],
  });

  useEffect(() => {
    (async () => {
      const { data } = await axios.get(`/api/admin/features/view`);
      setState({
        thematics: data.thematics,
        roles: data.roles,
        collapsed: data.thematics.map((thematic) => thematic.id),
      });
    })();
  }, []);

  return (
    <Container className='admin'>
      <Row>
        <h2>Fonctionnalité</h2>
      </Row>
      <Row>
        <Col>
          <Table striped bordered hover>
            <thead>
              <tr>
                <td>Fonctionnalité</td>
                {state.roles.map((role, key) => (
                  <td key={key} style={{ width: "125px" }}>
                    {role.label}
                  </td>
                ))}
              </tr>
            </thead>
            <tbody>
              {state.thematics.map((thematic, key) => {
                const currentGroupIndex = key * 1000;
                const rows = [];
                rows.push(
                  <tr key={currentGroupIndex}>
                    <td
                      colSpan={state.roles.length + 1}
                      style={{ backgroundColor: "#7E97C5" }}
                      onClick={() => {
                        if (state.collapsed.includes(thematic.id)) {
                          setState({
                            ...state,
                            collapsed: state.collapsed.filter(
                              (c) => c !== thematic.id
                            ),
                          });
                        } else {
                          setState({
                            ...state,
                            collapsed: [...state.collapsed, thematic.id],
                          });
                        }
                      }}>
                      <i
                        className={
                          state.collapsed.includes(thematic.id)
                            ? "fas fa-plus"
                            : "fas fa-minus"
                        }
                        style={{ paddingRight: "10px" }}
                      />
                      {thematic.label}
                    </td>
                  </tr>
                );
                if (!state.collapsed.includes(thematic.id)) {
                  thematic.features.forEach((feature, keyFeat) => {
                    rows.push(
                      <tr key={currentGroupIndex + keyFeat + 1}>
                        <td>
                          <i>{feature.id}</i> : {feature.label}
                        </td>
                        {state.roles.map((role, keyRole) => (
                          <td key={keyRole}>
                            {feature.roles.includes(role.id) ? (
                              <i className='fas fa-check'></i>
                            ) : (
                              ""
                            )}
                          </td>
                        ))}
                      </tr>
                    );
                  });
                }
                return rows;
              })}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
}
