import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  Row,
  Col,
  Container,
  InputGroup,
  FormControl,
  Modal,
} from "react-bootstrap";
import axios from "axios";

import PaginationBlock from "../tools/PaginationBlock";
import ReloadBlock from "../tools/ReloadBlock";
import LoadingBlock from "../tools/LoadingBlock";
import AccountEditUserPopup from "./AccountEditUserPopup";

export default function AccountUsers({ accountId, refreshAccount, canWrite }) {
  const [refresh, setRefresh] = useState(true);
  const [users, setUsers] = useState([]);
  const [countPerPage] = useState(5);
  const [activePage, setActivePage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [userSearch, setUserSearch] = useState("");
  const [userToDelete, setUserToDelete] = useState(null);
  const [showReload, setShowReload] = useState(false);
  const [showLoadingBlock, setShowLoadingBlock] = useState(false);
  const [loadingCallBack, setLoadingCallBack] = useState(null);
  const [state, setState] = useState({
    showAddUserPopup: false,
    userToEdit: {},
  });

  useEffect(() => {
    const requestName = userSearch ? `&search=${userSearch}` : "";
    axios
      .get(
        `/api/admin/accounts/${accountId}/users?max-results=${countPerPage}&start-index=${
          activePage - 1
        }${requestName}`
      )
      .then((response) => {
        setLastPage(Math.ceil(response.data.count / countPerPage));
        setUsers(response.data.users);
      })
      .catch(function (error) {
        // handle error
        setShowReload(true);
      });
  }, [accountId, refresh, userSearch, countPerPage, activePage]);

  const roleLabelApply = (role, key) => {
    switch (role) {
      case "role.read":
        return (
          <i
            key={key}
            style={{ paddingRight: "10px", backgroundColor: "#DCDCDC" }}>
            Lecture
          </i>
        );
      case "role.write":
        return (
          <i
            key={key}
            style={{ paddingRight: "10px", backgroundColor: "#5BC0C1" }}>
            Écriture
          </i>
        );
      case "role.access":
        return (
          <i
            key={key}
            style={{ paddingRight: "10px", backgroundColor: "#F7786B" }}>
            Gestion des accès
          </i>
        );
      default:
        return <i>Nono</i>;
    }
  };

  const rowUsers = users.map((user, key) => {
    const roles = user.roles.includes("role.admin") ? (
      <i style={{ paddingRight: "10px", backgroundColor: "#FFB400" }}>
        Gestion du compte{" "}
      </i>
    ) : (
      user.roles.map((role, index) => roleLabelApply(role, index))
    );

    return (
      <tr key={key}>
        <td>{user.email}</td>
        <td>{roles}</td>
        <td>{user.createdAt}</td>
        <td>
          {canWrite && (
            <>
              <span
                className='far fa-edit'
                style={{ cursor: "pointer", paddingRight: "10px" }}
                onClick={() => {
                  setState({
                    ...state,
                    showAddUserPopup: true,
                    userToEdit: user,
                  });
                }}
              />
              <span
                className='far fa-trash-alt'
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setUserToDelete(user.email);
                }}
              />
            </>
          )}
        </td>
      </tr>
    );
  });

  const modalLoading = showLoadingBlock ? (
    <LoadingBlock
      applyAction={loadingCallBack}
      closeModal={() => {
        setShowLoadingBlock(false);
        refreshAccount();
        setRefresh(!refresh);
      }}
    />
  ) : null;

  return showReload ? (
    <ReloadBlock
      callBack={() => {
        setShowReload(false);
        setRefresh(!refresh);
      }}
    />
  ) : (
    <Container>
      {modalLoading}
      <Row className='justify-content-md-center'>
        <Col>
          {canWrite && (
            <Button
              variant='info'
              onClick={() => {
                setState({
                  ...state,
                  showAddUserPopup: true,
                });
              }}>
              Ajouter un utilisateur
            </Button>
          )}
        </Col>
        <Col>
          <InputGroup className='mb-3'>
            <InputGroup.Prepend>
              <InputGroup.Text id='basic-addon1'>
                <span className='fas fa-search'></span>
              </InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              placeholder='Recherche ...'
              aria-label='search'
              aria-describedby='basic-addon1'
              value={userSearch}
              onChange={(e) => {
                setUserSearch(e.target.value);
                setActivePage(1);
              }}
            />
            <InputGroup.Append className='clear'>
              <InputGroup.Text id='basic-addon1'>
                <span
                  className='fas fa-times-circle'
                  onClick={() => {
                    setUserSearch("");
                    setActivePage(1);
                  }}></span>
              </InputGroup.Text>
            </InputGroup.Append>
          </InputGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table striped bordered hover className='users'>
            <thead>
              <tr>
                <th className='email' style={{ width: "500px" }}>
                  Email
                </th>
                <th className='email'>Rôles</th>
                <th className='email'>Date d'ajout</th>
                <th className='actions'>Actions</th>
              </tr>
            </thead>
            <tbody>{rowUsers}</tbody>
          </Table>
        </Col>
      </Row>
      <PaginationBlock
        lastPage={lastPage}
        refresh={(index) => {
          setActivePage(index);
          setRefresh(!refresh);
        }}
      />
      {state.showAddUserPopup && (
        <AccountEditUserPopup
          accountId={accountId}
          user={state.userToEdit}
          closePopup={() => {
            setState({
              ...state,
              showAddUserPopup: false,
              userToEdit: {},
            });
          }}
          submitUpdate={(updateAction) => {
            setLoadingCallBack(() => {
              updateAction();
            });
            setShowLoadingBlock(true);
          }}
        />
      )}
      <Modal
        show={userToDelete !== null}
        onHide={() => {
          setUserToDelete(null);
        }}
        aria-labelledby='contained-modal-title-vcenter'
        centered>
        <Modal.Body>
          <Container>
            <Row>
              <Col>
                Etes vous sur de vouloir retirer du compte l'utilisateur{" "}
                {userToDelete} ?
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='info'
            onClick={() => {
              setLoadingCallBack(() => {
                axios
                  .delete(
                    `/api/admin/accounts/${accountId}/users/${userToDelete}`
                  )
                  .then(() => {
                    setUserToDelete(null);
                  });
              });
              setShowLoadingBlock(true);
            }}>
            Confirmer
          </Button>
          <Button
            variant='light'
            onClick={() => {
              setUserToDelete(null);
            }}>
            Annuler
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}
