import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Modal,
  Table,
} from "react-bootstrap";
import axios from "axios";
import "./Admin.scss";
import AdminBlock from "./AdminBlock";

export default function Admin() {
  const [toastLabel, setToastLabel] = useState(null);
  const [showToast, setShowToast] = useState(false);
  const [replayProjections, setReplayProjections] = useState([]);
  const [replayProjectionSelected, setReplayProjectionSelected] = useState("");
  const [pollingLinesUpdate, setPollingLinesUpdate] = useState(false);
  const [pollingLines, setPollingLines] = useState([]);

  useEffect(() => {
    axios
      .get(`/api/admin/projector/`)
      .then((response) => setReplayProjections(response.data))
      .catch((error) => console.log(error));
  }, []);

  const closeRefreshModal = () => {
    setToastLabel(null);
    setShowToast(false);
  };

  const refreshProjection = () => {
    if (replayProjectionSelected) {
      axios.get(`/api/admin/projector/replay/${replayProjectionSelected}`);
      setToastLabel(
        `Rafraichissement de la projection ${
          replayProjections.find(
            (replay) => replay.id === replayProjectionSelected
          ).label
        } lancé`
      );
      setShowToast(true);
      setReplayProjectionSelected("");
      setTimeout(closeRefreshModal, 2000);
    }
  };

  const options = replayProjections.map((replay, key) => {
    return (
      <option key={key} value={replay.id}>
        {replay.label}
      </option>
    );
  });

  const refreshFeatures = () => {
    axios.get(`/api/admin/features/refresh`);
    setToastLabel(`Rafraichissement des fonctionnalités lancé`);
    setShowToast(true);
    setTimeout(closeRefreshModal, 2000);
  };

  const getPollingLines = async () => {
    if (!pollingLinesUpdate) {
      setPollingLinesUpdate(true);
      const { data } = await axios.get("/api/admin/pollingstatus");
      const lines = data.map((d, key) => (
        <tr key={key}>
          <td>{d.importId}</td>
          <td>{d.fileId}</td>
          <td>{d.status}</td>
          <td>{d.accountId}</td>
          <td>{d.type}</td>
          <td>{d.updated}</td>
          <td>
            <i
              className='far fa-trash-alt'
              style={{ cursor: "pointer" }}
              onClick={async () => {
                await axios.delete(`/api/admin/pollingstatus/${d.importId}`);
                getPollingLines();
              }}
            />
          </td>
        </tr>
      ));
      setPollingLines(lines);
      setPollingLinesUpdate(false);
    }
  };

  return (
    <Container className='admin'>
      <Row>
        <h2>Administration</h2>
      </Row>
      <Modal show={showToast} onHide={closeRefreshModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Action d'administration</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{toastLabel}</p>
        </Modal.Body>
      </Modal>
      <AdminBlock
        title={"Rafraichir la projection"}
        content={
          <Row>
            <Col xs={9}>
              <Form.Group controlId='admin.refreshProjection'>
                <Form.Control
                  as='select'
                  value={replayProjectionSelected}
                  onChange={(e) => {
                    setReplayProjectionSelected(e.target.value);
                  }}>
                  <option value=''></option>
                  {options}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col xs={2}>
              <Button variant='success' onClick={() => refreshProjection()}>
                Lancer
              </Button>
            </Col>
          </Row>
        }
      />
      <AdminBlock
        title={"Rafraichir les fonctionnalités des comptes"}
        content={
          <Row>
            <Col xs={2}>
              <Button variant='success' onClick={refreshFeatures}>
                Lancer
              </Button>
            </Col>
          </Row>
        }
      />
      <AdminBlock
        title={"Liste des imports de filtres pre/custo en cours"}
        content={
          <>
            <i
              className='fas fa-sync'
              style={{ cursor: "pointer" }}
              onClick={getPollingLines}
            />
            <Row>
              <Col xs={6}>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>importId</th>
                      <th>fileId</th>
                      <th>status</th>
                      <th>accountId</th>
                      <th>type</th>
                      <th>updated</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>{pollingLines}</tbody>
                </Table>
              </Col>
            </Row>
          </>
        }
      />
    </Container>
  );
}
