import React, { useState } from "react";
import NumberFormat from "react-number-format";
import { Row, Col, Container, Form, Button } from "react-bootstrap";
import axios from "axios";

import LoadingBlock from "../tools/LoadingBlock";

export default function AccountCredit({ account, refreshAccount }) {
  const [modifyExport, setModifyExport] = useState(false);
  const [modifyEmailNomi, setModifyEmailNomi] = useState(false);
  const [emailSelected, setEmailSelected] = useState("");
  const [showLoadingBlock, setShowLoadingBlock] = useState(false);
  const [newExport, setNewExport] = useState(
    account.balances["siret"] ? account.balances["siret"] : 0
  );
  const [newEmailNomi, setNewEmailNomi] = useState(
    account.balances["emailNominatif"] ? account.balances["emailNominatif"] : 0
  );
  const [newEmailList, setNewEmailList] = useState([]);

  const creditExportLabel = "Crédits d'export";
  const creditEmailNomiLabel = "Crédits d'emails nominatifs";
  const creditEmailLabel = "Crédits d'envois d'emails (Emailer)";
  const creditEmailMCampaignLabel = "Crédits d'envois mCampaign";

  const emailOptions = [
    { value: "3000", label: " Offre de test 3000" },
    { value: "10000", label: " EMAIL_V10" },
    { value: "25000", label: " EMAIL_V25" },
    { value: "50000", label: " EMAIL_V50" },
    { value: "100000", label: " EMAIL_V100" },
    { value: "200000", label: " EMAIL_V200" },
    { value: "250000", label: " EMAIL_V250" },
    { value: "500000", label: " EMAIL_V500" },
    { value: "1000000", label: " EMAIL_V1000" },
  ];

  const selectOptions = emailOptions.map((value, key) => {
    return (
      <option key={key} value={value.value}>
        {value.label}
      </option>
    );
  });

  const getModifyLink = (valueState, onClickAction, newValue, setNewValue) => {
    return valueState ? (
      <Form.Control
        type='text'
        value={newValue}
        onChange={(e) => {
          setNewValue(e.target.value);
        }}
        pattern='\d*'
      />
    ) : (
      <p className='modify' onClick={onClickAction}>
        Modifier le nombre de crédit
      </p>
    );
  };
  const modalLoading = showLoadingBlock ? (
    <LoadingBlock
      applyAction={() => {
        axios.put(`/api/admin/accounts/${account.id}/credits`, {
          credits: {
            siret: newExport,
            emailNominatif: newEmailNomi,
          },
          emails: newEmailList.map((e) => e.value),
        });
      }}
      closeModal={() => {
        setShowLoadingBlock(false);
        refreshAccount();
        reset();
      }}
    />
  ) : null;

  const newEmailListDiv = newEmailList.map((value, key) => {
    return (
      <li key={key}>
        {" "}
        <p
          className='modify'
          onClick={() => {
            const index = newEmailList.indexOf(
              (email) => email.value !== value.value
            );
            newEmailList.splice(index);
            setNewEmailList([...newEmailList]);
          }}>
          {value.label}
        </p>
      </li>
    );
  });

  const reset = () => {
    setModifyExport(false);
    setModifyEmailNomi(false);
    setEmailSelected("");
    setNewEmailList([]);
  };

  return (
    <Container className='account-credit'>
      {modalLoading}
      <Row>
        <Col xs={5}>
          <h2>Crédits pour le compte</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <ul>
            <li>
              <span className='bold'>{creditExportLabel} : </span>
              <NumberFormat
                value={
                  account.balances["siret"] ? account.balances["siret"] : 0
                }
                displayType={"text"}
                thousandSeparator=' '
              />
            </li>
            <li>
              <span className='bold'>{creditEmailNomiLabel} : </span>
              <NumberFormat
                value={
                  account.balances["emailNominatif"]
                    ? account.balances["emailNominatif"]
                    : 0
                }
                displayType={"text"}
                thousandSeparator=' '
              />
            </li>
            <li>
              <span className='bold'>{creditEmailLabel} : </span>
              <NumberFormat
                value={account.emailCredit ? account.emailCredit : 0}
                displayType={"text"}
                thousandSeparator=' '
              />
            </li>
            <li>
              <span className='bold'>{creditEmailMCampaignLabel} : </span>
              <span>{account.emailCreditMCampaign}</span>
            </li>
          </ul>
        </Col>
      </Row>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          setShowLoadingBlock(true);
        }}
        onReset={reset}>
        <Row>
          <Col>{creditExportLabel}</Col>
        </Row>
        <Row>
          <Col xs={3}>
            {getModifyLink(
              modifyExport,
              () => {
                setModifyExport(true);
              },
              newExport,
              setNewExport
            )}
          </Col>
        </Row>
        <Row>
          <Col>{creditEmailNomiLabel}</Col>
        </Row>
        <Row>
          <Col xs={3}>
            {getModifyLink(
              modifyEmailNomi,
              () => {
                setModifyEmailNomi(true);
              },
              newEmailNomi,
              setNewEmailNomi
            )}
          </Col>
        </Row>
        <Row>
          <Col>{creditEmailLabel}</Col>
        </Row>
        <Row>
          <Col xs={3}>
            <Form.Group controlId='exampleForm.ControlSelect1'>
              <Form.Control
                as='select'
                value={emailSelected && emailSelected.value}
                onChange={(e) => {
                  setEmailSelected(e.target.value);
                }}>
                <option></option>
                {selectOptions}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col xs={1} style={{ display: "flex" }}>
            <p
              className='modify'
              onClick={() => {
                var value = emailOptions.find(
                  (email) => email.value === emailSelected
                );
                if (value) {
                  setNewEmailList([...newEmailList, value]);
                  setEmailSelected("");
                }
              }}>
              Ajouter
            </p>
          </Col>
        </Row>
        <Row>
          <Col xs={3}>
            <ul>{newEmailListDiv}</ul>
          </Col>
        </Row>
        <Row>
          <Col xs={2}>
            <Button variant='primary' type='submit'>
              Sauvegarder
            </Button>
          </Col>
          <Col xs={2}>
            <Button variant='light' type='reset'>
              Annuler
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
}
