import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
  Container,
  Row,
  Col,
  Button,
  InputGroup,
  FormControl,
  Table,
} from "react-bootstrap";
import { NavLink } from "react-router-dom";
import axios from "axios";
import PaginationBlock from "../tools/PaginationBlock";
import "./Accounts.scss";

export default function Accounts({ canWrite }) {
  const [updateAccounts, setUpdateAccounts] = useState(true);
  const [accounts, setAccounts] = useState([]);
  const [countPerPage] = useState(10);
  const [activePage, setActivePage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [accountSearch, setAccountSearch] = useState("");

  useEffect(() => {
    const requestName = accountSearch ? `&search=${accountSearch}` : "";
    axios
      .get(
        `/api/admin/accounts?max-results=${countPerPage}&start-index=${
          activePage - 1
        }${requestName}`
      )
      .then((response) => {
        setLastPage(Math.ceil(response.data.count / countPerPage));
        setAccounts(response.data.accounts);
      });

    // eslint-disable-next-line
  }, [updateAccounts, accountSearch]);

  const accountRows = _.map(accounts, (account, index) => {
    return (
      <tr key={index}>
        <td>{account.id.substring(0, 8)}</td>
        <td>
          <NavLink to={`/accounts/${account.id}`}>{account.name}</NavLink>
        </td>
        <td>{account.subscriptionId}</td>
        <td>{account.plan}</td>
        <td>{account.active ? "activé" : "désactivé"}</td>
        <td>{account.created}</td>
        <td>{account.updated}</td>
      </tr>
    );
  });

  const createAccountButton = canWrite ? (
    <NavLink to={`/create/`}>
      <Button variant='success'>Ajouter un compte</Button>
    </NavLink>
  ) : null;

  return (
    <Container className='accounts'>
      <Row>
        <Col>
          <h2>Listes de comptes</h2>
        </Col>
      </Row>
      <Row className='justify-content-md-center'>
        <Col>{createAccountButton}</Col>
        <Col>
          <InputGroup className='mb-3'>
            <InputGroup.Prepend>
              <InputGroup.Text id='basic-addon1'>
                <span className='fas fa-search'></span>
              </InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              placeholder='Recherche ...'
              aria-label='search'
              aria-describedby='basic-addon1'
              value={accountSearch}
              onChange={(e) => {
                setAccountSearch(e.target.value);
                setActivePage(1);
              }}
            />
            <InputGroup.Append className='clear'>
              <InputGroup.Text id='basic-addon1'>
                <span
                  className='fas fa-times-circle'
                  onClick={() => {
                    setAccountSearch("");
                    setActivePage(1);
                  }}></span>
              </InputGroup.Text>
            </InputGroup.Append>
          </InputGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th className='id'>Identifiant</th>
                <th className='name'>Nom</th>
                <th className='subscriptionId'>Id CRM</th>
                <th className='state'>Plan</th>
                <th className='state'>Etat</th>
                <th className='date'>Date de création</th>
                <th className='date'>Dernière mise à jour</th>
              </tr>
            </thead>
            <tbody>{accountRows}</tbody>
          </Table>
        </Col>
      </Row>

      <PaginationBlock
        lastPage={lastPage}
        refresh={(index) => {
          setActivePage(index);
          setUpdateAccounts(!updateAccounts);
        }}
      />
    </Container>
  );
}
