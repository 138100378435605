import React from "react";
import { Col, Container, Row } from "react-bootstrap";

export default function AdminBlock({ title, content }) {
  return (
    <Container className='admin-block'>
      <Row>
        <Col>
          <h4>{title}</h4>
        </Col>
      </Row>
      {content}
    </Container>
  );
}
