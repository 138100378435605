import React, { useState, useEffect, useCallback } from "react";
import { Row, Col, Container, Modal, Button } from "react-bootstrap";
import DualListBox from "react-dual-listbox";
import "react-dual-listbox/lib/react-dual-listbox.css";
import axios from "axios";

import LoadingBlock from "../tools/LoadingBlock";

export default function AccountFeatures({
  accountId,
  canWrite,
  refreshAccount,
}) {
  const [state, setState] = useState({
    plans: [],
    options: [],
    selected: [],
    usersFeatures: [],
    thematics: [],
    showFeatureUpdateModal: false,
    toAddFeatures: [],
    toDeleteFeatures: [],
    showLoadingBlock: false,
    actionToApply: null,
    actionRefresh: null,
  });

  const refreshFeatures = useCallback(async () => {
    const response = await axios.get(
      `/api/admin/accounts/${accountId}/features/view`
    );

    const options = response.data.thematics
      .map((thematic) => {
        const subOptions = thematic.groups.map((group) => {
          return { value: group.id, label: group.label };
        });
        return {
          label: thematic.label,
          options: subOptions,
        };
      })
      .filter((option) => option.options.length);

    setState({
      showFeatureUpdateModal: false,
      toAddFeatures: [],
      toDeleteFeatures: [],
      showLoadingBlock: false,
      actionToApply: null,
      actionRefresh: null,
      plans: response.data.plans,
      options: options,
      usersFeatures: response.data.usersFeatures,
      selected: response.data.usersFeatures,
      thematics: response.data.thematics,
      planId: response.data.planId,
    });
  }, [accountId]);

  useEffect(() => {
    refreshFeatures();
  }, [refreshFeatures]);

  const updateFeatures = () => {
    const groups = state.thematics.flatMap((thematic) => thematic.groups);
    const existingFeatures = groups.filter((group) =>
      state.usersFeatures.includes(group.id)
    );
    const toAddFeatures = state.selected
      .filter((f) => !existingFeatures.map((g) => g.id).includes(f))
      .map((id) => groups.find((g) => g.id === id))
      .flatMap((g) => g.features);
    const toDeleteFeatures = existingFeatures
      .map((g) => g.id)
      .filter((f) => !state.selected.includes(f))
      .map((id) => groups.find((g) => g.id === id))
      .flatMap((g) => g.features);
    if (toAddFeatures.length || toDeleteFeatures.length) {
      setState({
        ...state,
        toAddFeatures: toAddFeatures,
        toDeleteFeatures: toDeleteFeatures,
        showFeatureUpdateModal: true,
      });
    }
  };

  const submitFeatureUpdate = () => {
    setState({
      ...state,
      showFeatureUpdateModal: false,
      showLoadingBlock: true,
      actionToApply: async () => {
        if (state.toAddFeatures.length) {
          await axios.put(
            `/api/admin/accounts/${accountId}/features`,
            state.toAddFeatures.map((f) => f.id)
          );
        }
        if (state.toDeleteFeatures.length) {
          await axios.delete(`/api/admin/accounts/${accountId}/features`, {
            data: state.toDeleteFeatures.map((f) => f.id),
          });
        }
      },
      actionRefresh: () => {
        refreshAccount();
        refreshFeatures();
      },
    });
  };

  const toAddRow = state.toAddFeatures.map((f, key) => (
    <li key={key}>{f.label}</li>
  ));
  const toDeleteRow = state.toDeleteFeatures.map((f, key) => (
    <li key={key}>{f.label}</li>
  ));

  return (
    <Container>
      <Row>
        <Col></Col>
      </Row>
      <Row>
        {canWrite &&
          state.plans.map((plan, key) => {
            return (
              <Button
                key={key}
                size='sm'
                variant={plan.id === state.planId ? "light" : "info"}
                className='button-plan'
                onClick={() => {
                  setState({
                    ...state,
                    showLoadingBlock: true,
                    actionToApply: async () => {
                      await axios.put(
                        `/api/admin/accounts/${accountId}/features/plan/${plan.id}`
                      );
                    },
                    actionRefresh: () => {
                      refreshAccount();
                      refreshFeatures();
                    },
                  });
                }}>
                {plan.label}
              </Button>
            );
          })}
      </Row>
      <Row>
        <Col>
          {canWrite ? (
            <DualListBox
              options={state.options}
              selected={state.selected}
              onChange={(selected) => {
                setState({
                  ...state,
                  selected: selected,
                });
              }}
            />
          ) : (
            <DualListBox
              disabled
              options={state.options}
              selected={state.selected}
              onChange={() => {}}
            />
          )}
        </Col>
      </Row>
      {canWrite && (
        <Row>
          <Col xs={{ span: 2, offset: 4 }}>
            <Button variant='info' onClick={updateFeatures}>
              Sauvegarder
            </Button>
          </Col>
          <Col xs={2}>
            <Button
              variant='light'
              onClick={() => {
                setState({
                  ...state,
                  selected: state.usersFeatures,
                });
              }}>
              Annuler
            </Button>
          </Col>
        </Row>
      )}
      <Row>
        <Col></Col>
      </Row>
      <Modal
        show={state.showFeatureUpdateModal}
        onHide={() => {
          setState({
            ...state,
            showFeatureUpdateModal: false,
          });
        }}
        aria-labelledby='contained-modal-title-vcenter'
        centered
        className='addFeatures'>
        <Modal.Body>
          <Container>
            <Row>
              <Col>
                <h3>Confirmer la mise à jour des fonctionnalités</h3>
              </Col>
            </Row>
            <Row>
              <Col className='info'>
                Vous allez ajouter les fonctionnalités suivantes :
              </Col>
            </Row>
            <Row>
              <Col>
                <ul>{toAddRow}</ul>
              </Col>
            </Row>
            <Row>
              <Col className='info'>
                Vous allez retirer les fonctionnalités suivantes :
              </Col>
            </Row>
            <Row>
              <Col>
                <ul>{toDeleteRow}</ul>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='info'
            onClick={() => {
              submitFeatureUpdate();
            }}>
            Confirmer
          </Button>
          <Button
            variant='light'
            onClick={() => {
              setState({
                ...state,
                showFeatureUpdateModal: false,
              });
            }}>
            Annuler
          </Button>
        </Modal.Footer>
      </Modal>
      {state.showLoadingBlock && (
        <LoadingBlock
          applyAction={state.actionToApply}
          closeModal={() => {
            setState({
              ...state,
              showLoadingBlock: false,
            });
            if (state.actionRefresh) {
              state.actionRefresh();
            }
          }}
        />
      )}
    </Container>
  );
}
