import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { Row, Col, Container, Table, Modal, Button } from "react-bootstrap";

import "./Account.scss";

import LoadingBlock from "../tools/LoadingBlock";

export default function AccountFilters({ accountId, refreshAccount }) {
  const [state, setState] = useState({
    showConfirm: false,
    showLoadingBlock: false,
    confirmAction: null,
    confirmMessage: null,
    loadingCallBack: null,
    loadingClose: null,
    filterToDrop: null,
    prefilters: [],
    customFilters: [],
  });

  const refreshFilters = useCallback(async () => {
    const responsePreFilters = await axios.get(
      `/api/admin/accounts/${accountId}/prefilters`
    );
    const preFilters = responsePreFilters.data;
    const responseCustomFilters = await axios.get(
      `/api/admin/accounts/${accountId}/customfilters`
    );
    const customFilters = responseCustomFilters.data;
    setState({
      showConfirm: false,
      showLoadingBlock: false,
      confirmAction: null,
      confirmMessage: null,
      loadingCallBack: null,
      loadingClose: null,
      filterToDrop: null,
      prefilters: preFilters,
      customFilters: customFilters,
    });
  }, [accountId]);

  useEffect(() => {
    console.info("refreshFilters");
    refreshFilters();
  }, [refreshFilters]);

  const getFilterLine = (filters, type) => {
    const filterMessage =
      type === "PRE-FILTER" ? "pré-filtre" : "filtre personnalisé";
    return filters.map((filter, key) => {
      return (
        <tr key={key}>
          <td>{filter.id}</td>
          <td>{filter.name}</td>
          <td>{filter.status}</td>
          <td>{filter.createdOn}</td>
          <td>
            <i
              className='far fa-trash-alt'
              onClick={() => {
                setState({
                  ...state,
                  showConfirm: true,
                  confirmMessage: `du ${filterMessage} ${filter.name}`,
                  filterToDrop: {
                    ...filter,
                    type: type,
                  },
                });
              }}
            />
          </td>
        </tr>
      );
    });
  };

  return (
    accountId && (
      <Container className='account-credit'>
        <Row>
          <Col></Col>
        </Row>
        <Row>
          <Col>Pré-filtres</Col>
        </Row>
        <Row>
          <Col>
            <Table striped bordered hover className='users'>
              <thead>
                <tr>
                  <td>Identifiant</td>
                  <td>Nom</td>
                  <td>Etat</td>
                  <td>Date de création</td>
                  <td>Action</td>
                </tr>
              </thead>
              <tbody>{getFilterLine(state.prefilters, "PRE-FILTER")}</tbody>
            </Table>
          </Col>
        </Row>
        <Row>
          <Col>Filtres personnalisés</Col>
        </Row>
        <Row>
          <Col>
            <Table striped bordered hover className='users'>
              <thead>
                <tr>
                  <td>Identifiant</td>
                  <td>Nom</td>
                  <td>Etat</td>
                  <td>Date de création</td>
                  <td>Action</td>
                </tr>
              </thead>
              <tbody>
                {getFilterLine(state.customFilters, "CUSTOM-FILTER")}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row>
          <Col></Col>
        </Row>
        <Modal
          show={state.showConfirm}
          onHide={() => {
            setState({
              ...state,
              showConfirm: false,
              confirmMessage: null,
              filterToDrop: null,
            });
          }}
          aria-labelledby='contained-modal-title-vcenter'
          centered>
          <Modal.Body>
            <Container>
              <Row>
                <Col>Confirmer la suppression {state.confirmMessage}</Col>
              </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant='info'
              onClick={() => {
                setState({
                  ...state,
                  showConfirm: false,
                  showLoadingBlock: true,
                });
              }}>
              Confirmer
            </Button>
            <Button
              variant='light'
              onClick={() => {
                setState({
                  ...state,
                  showConfirm: false,
                  confirmMessage: null,
                  filterToDrop: null,
                });
              }}>
              Annuler
            </Button>
          </Modal.Footer>
        </Modal>
        {state.showLoadingBlock && (
          <LoadingBlock
            applyAction={async () => {
              if (!state.filterToDrop) {
                return;
              }
              if (state.filterToDrop.type === "PRE-FILTER") {
                await axios.delete(
                  `/api/admin/accounts/${accountId}/prefilters/${state.filterToDrop.id}`
                );
              } else if (state.filterToDrop.type === "CUSTOM-FILTER") {
                await axios.delete(
                  `/api/admin/accounts/${accountId}/customfilters/${state.filterToDrop.id}`
                );
              }
            }}
            closeModal={() => {
              refreshFilters();
              refreshAccount();
            }}
          />
        )}
      </Container>
    )
  );
}
