import React, { useState } from "react";
import { Row, Col, Button, Form } from "react-bootstrap";

export default function AccountCreateForm({
  actionSubmit,
  actionReset,
  showAddCredit,
  accountToModify,
  justifyCenter,
  dropAccountAction,
}) {
  const [account, setAccount] = useState(
    accountToModify
      ? { ...accountToModify }
      : {
          name: "",
          dataviz: 0,
          customFilterQuota: 0,
          preFilterQuota: 0,
          credit: 0,
          userQuota: 0,
          active: true,
          subscriptionId: "",
          spothitId: "",
        }
  );

  const dropAccountCol = dropAccountAction ? (
    <Col className='dropAccount' xs={{ span: 4, offset: 3 }}>
      <Button variant='light' onClick={dropAccountAction}>
        Supprimer compte
      </Button>
    </Col>
  ) : null;

  const addCreditForm = showAddCredit ? (
    <>
      <h2>Ajout de crédits</h2>
      <Form.Group controlId='formCredit'>
        <Form.Label className='title'>Crédits</Form.Label>
        <Form.Control
          className='from-number'
          type='text'
          value={account.credit}
          onChange={(e) => {
            setAccount({ ...account, credit: e.target.value });
          }}
          pattern='\d*'
        />
      </Form.Group>
    </>
  ) : null;

  const accountButtonReset = actionReset ? (
    <Button variant='light' type='reset'>
      Annuler
    </Button>
  ) : null;
  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        actionSubmit(account);
      }}
      onReset={(e) => {
        e.preventDefault();
        actionReset();
      }}>
      <Row className={justifyCenter ? "justify-content-md-center" : ""}>
        <Col xs={5}>
          <h2>Informations générales</h2>

          <Form.Group controlId='formName'>
            <Form.Label className='title'>Nom</Form.Label>
            <Form.Control
              type='text'
              className='from-number'
              value={account.name}
              onChange={(e) => {
                setAccount({ ...account, name: e.target.value });
              }}
              required
            />
          </Form.Group>
          <Form.Group controlId='formDataviz'>
            <Form.Label className='title'>Dataviz</Form.Label>
            <Form.Control
              className='from-number'
              type='text'
              value={account.dataviz ? account.dataviz : 0}
              onChange={(e) => {
                setAccount({ ...account, dataviz: e.target.value });
              }}
              pattern='\d*'
              required
            />
          </Form.Group>
          <Form.Group controlId='formCustomFilter'>
            <Form.Label className='title'>Filtre personnalisé</Form.Label>
            <Form.Control
              className='from-number'
              type='text'
              value={
                account.customFilterQuota !== null
                  ? account.customFilterQuota
                  : ""
              }
              onChange={(e) => {
                setAccount({ ...account, customFilterQuota: e.target.value });
              }}
              pattern='\d*'
              required
            />
          </Form.Group>
          <Form.Group controlId='formPreFilter'>
            <Form.Label className='title'>Pré-filtre</Form.Label>
            <Form.Control
              className='from-number'
              type='text'
              value={
                account.preFilterQuota !== null ? account.preFilterQuota : ""
              }
              onChange={(e) => {
                setAccount({ ...account, preFilterQuota: e.target.value });
              }}
              pattern='\d*'
              required
            />
          </Form.Group>
          <Form.Group controlId='formUser'>
            <Form.Label className='title'>Utilisateurs</Form.Label>
            <Form.Control
              className='from-number'
              type='text'
              value={account.userQuota !== null ? account.userQuota : ""}
              onChange={(e) => {
                setAccount({ ...account, userQuota: e.target.value });
              }}
              pattern='\d*'
              required
            />
          </Form.Group>
          <Form.Group controlId='formActivated'>
            <Form.Label className='title'>Etat</Form.Label>
            <Form.Check
              type='checkbox'
              checked={!account.active}
              label='Désactiver le compte'
              onChange={() => {
                setAccount({ ...account, active: !account.active });
              }}
            />
          </Form.Group>
          <Form.Group controlId='formActivated'>
            <Form.Label className='title'>Identifiant CRM</Form.Label>
            <Form.Control
              className='from-number'
              type='text'
              value={
                account.subscriptionId !== null ? account.subscriptionId : ""
              }
              onChange={(e) => {
                setAccount({ ...account, subscriptionId: e.target.value });
              }}
            />
          </Form.Group>
          <Form.Group controlId='formActivated'>
            <Form.Label className='title'>
              Connexion mCampaign : ID sous-compte Spot-Hit{" "}
            </Form.Label>
            <Form.Control
              className='from-number'
              type='text'
              value={account.spothitId !== null ? account.spothitId : ""}
              onChange={(e) => {
                setAccount({ ...account, spothitId: e.target.value });
              }}
            />
          </Form.Group>
          {addCreditForm}
        </Col>
        {dropAccountCol}
      </Row>
      <Row className={justifyCenter ? "justify-content-md-center" : ""}>
        <Col xs={2}>
          <Button variant='success' type='submit'>
            Sauvegarder
          </Button>
        </Col>
        <Col xs={3} style={{ textAlign: "right" }}>
          {accountButtonReset}
        </Col>
      </Row>
    </Form>
  );
}
