import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
  Table,
  Button,
  Row,
  Col,
  Container,
  InputGroup,
  FormControl,
  Form,
  Modal,
} from "react-bootstrap";
import axios from "axios";

import PaginationBlock from "../tools/PaginationBlock";
import ReloadBlock from "../tools/ReloadBlock";
import LoadingBlock from "../tools/LoadingBlock";

export default function AccountTechnicalUsers({
  accountId,
  refreshAccount,
  canWrite,
}) {
  const [refresh, setRefresh] = useState(true);
  const [users, setUsers] = useState([]);
  const [countPerPage] = useState(5);
  const [activePage, setActivePage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [userSearch, setUserSearch] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [showUserAdd, setShowUserAdd] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [showReload, setShowReload] = useState(false);
  const [showLoadingBlock, setShowLoadingBlock] = useState(false);
  const [loadingCallBack, setLoadingCallBack] = useState(null);

  useEffect(() => {
    const requestName = userSearch ? `&search=${userSearch}` : "";
    axios
      .get(
        `/api/admin/accounts/${accountId}/technicalusers?max-results=${countPerPage}&start-index=${
          activePage - 1
        }${requestName}`
      )
      .then((response) => {
        setLastPage(Math.ceil(response.data.count / countPerPage));
        setUsers(response.data.users);
      })
      .catch(function (error) {
        // handle error
        setShowReload(true);
      });
  }, [accountId, refresh, userSearch, countPerPage, activePage]);

  const rowUsers = _.map(users, (user, key) => {
    const button = canWrite ? (
      <Button
        key={key}
        size='sm'
        variant='danger'
        onClick={() => {
          setUserToDelete(user.email);
        }}>
        Suppression
      </Button>
    ) : null;
    return (
      <tr key={key}>
        <td>{user.email}</td>
        <td>{button}</td>
      </tr>
    );
  });

  const modalLoading = showLoadingBlock ? (
    <LoadingBlock
      applyAction={loadingCallBack}
      closeModal={() => {
        setShowLoadingBlock(false);
        refreshAccount();
        setRefresh(!refresh);
      }}
    />
  ) : null;

  const buttonAdd = canWrite ? (
    <Button
      variant='info'
      onClick={() => {
        setShowUserAdd(true);
      }}>
      Ajouter un utilisateur
    </Button>
  ) : null;

  return showReload ? (
    <ReloadBlock
      callBack={() => {
        setShowReload(false);
        setRefresh(!refresh);
      }}
    />
  ) : (
    <Container>
      {modalLoading}
      <Row className='justify-content-md-center'>
        <Col>{buttonAdd}</Col>
        <Col>
          <InputGroup className='mb-3'>
            <InputGroup.Prepend>
              <InputGroup.Text id='basic-addon1'>
                <span className='fas fa-search'></span>
              </InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              placeholder='Recherche ...'
              aria-label='search'
              aria-describedby='basic-addon1'
              value={userSearch}
              onChange={(e) => {
                setUserSearch(e.target.value);
                setActivePage(1);
              }}
            />
            <InputGroup.Append className='clear'>
              <InputGroup.Text id='basic-addon1'>
                <span
                  className='fas fa-times-circle'
                  onClick={() => {
                    setUserSearch("");
                    setActivePage(1);
                  }}></span>
              </InputGroup.Text>
            </InputGroup.Append>
          </InputGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table striped bordered hover className='users'>
            <thead>
              <tr>
                <th className='email'>Email</th>
                <th className='actions'>Actions</th>
              </tr>
            </thead>
            <tbody>{rowUsers}</tbody>
          </Table>
        </Col>
      </Row>
      <PaginationBlock
        lastPage={lastPage}
        refresh={(index) => {
          setActivePage(index);
          setRefresh(!refresh);
        }}
      />
      <Modal
        show={showUserAdd}
        onHide={() => {
          setShowUserAdd(false);
        }}
        aria-labelledby='contained-modal-title-vcenter'
        centered>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            setLoadingCallBack(() => {
              axios
                .put(
                  `/api/admin/accounts/${accountId}/technicalusers/${userEmail}`
                )
                .then(() => {
                  setUserEmail("");
                  setShowUserAdd(false);
                });
            });
            setShowLoadingBlock(true);
          }}
          onReset={(e) => {
            e.preventDefault();
            setShowUserAdd(false);
          }}>
          <Modal.Body>
            <Form.Group controlId='formUserAdd'>
              <Form.Label className='title'>Ajouter un utilisateur</Form.Label>
              <Form.Control
                type='text'
                className='from-number'
                value={userEmail}
                placeholder='Adresse email'
                onChange={(e) => {
                  setUserEmail(e.target.value);
                }}
                pattern='^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$'
                required
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='success' type='submit'>
              Sauvegarder
            </Button>
            <Button variant='light' type='reset'>
              Close
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      <Modal
        show={userToDelete !== null}
        onHide={() => {
          setUserToDelete(null);
        }}
        aria-labelledby='contained-modal-title-vcenter'
        centered>
        <Modal.Body>
          <Container>
            <Row>
              <Col>
                Etes vous sur de vouloir retirer du compte l'utilisateur{" "}
                {userToDelete} ?
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='info'
            onClick={() => {
              setLoadingCallBack(() => {
                axios
                  .delete(
                    `/api/admin/accounts/${accountId}/technicalusers/${userToDelete}`
                  )
                  .then(() => {
                    setUserToDelete(null);
                  });
              });
              setShowLoadingBlock(true);
            }}>
            Confirmer
          </Button>
          <Button
            variant='light'
            onClick={() => {
              setUserToDelete(null);
            }}>
            Annuler
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}
