import axios from "axios";
import React, { useEffect, useState, useCallback } from "react";
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import NumberFormat from "react-number-format";
import "./Account.scss";
import AccountChange from "./AccountChange";
import AccountCredit from "./AccountCredit";
import AccountFeatures from "./AccountFeatures";
import AccountSettings from "./AccountSettings";
import AccountTechnicalUsers from "./AccountTechnicalUsers";
import AccountUsage from "./AccountUsage";
import AccountUsers from "./AccountUsers";
import AccountFilters from "./AccountFilters";

export default function Account({ match, canWrite, canManageUsers, canRead }) {
  const [state, setState] = useState({
    accountId: match.params.accountId,
    tabSelected: canManageUsers ? "users" : "features",
  });
  const [account, setAccount] = useState(null);

  const refreshAccount = useCallback(async () => {
    const { data } = await axios.get(
      `/api/admin/accounts/${match.params.accountId}`
    );
    setAccount(data);
  }, [match.params.accountId]);

  useEffect(() => {
    refreshAccount();
  }, [refreshAccount]);

  const formatNumber = (number) => (
    <NumberFormat
      value={number ? number : 0}
      displayType={"text"}
      thousandSeparator=' '
    />
  );
  return (
    account && (
      <div className='account'>
        <Container>
          <Row>
            <Col>
              <h1>Détail du compte</h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <a href='/'>Tous</a> &rsaquo; {account.name}
            </Col>
          </Row>
          <Row>
            <Col xs={3} className='title'>
              Identifiant
            </Col>
            <Col xs={4} className='value'>
              {account.id}
            </Col>
            <Col xs={2} className='title'>
              Date de création
            </Col>
            <Col xs={3} className='value'>
              {account.created}
            </Col>
          </Row>
          <Row>
            <Col xs={3} className='title'>
              Nom
            </Col>
            <Col xs={4} className='value'>
              {account.name}
            </Col>
            <Col xs={2} className='title'>
              Date de mise à jour
            </Col>
            <Col xs={3} className='value'>
              {account.updated}
            </Col>
          </Row>
          <Row>
            <Col xs={3} className='title'></Col>
            <Col xs={4} className='value'></Col>
            <Col xs={2} className='title'>
              Dernière connexion
            </Col>
            <Col xs={3} className='value'>
              {account.lastConnect}
            </Col>
          </Row>
          {canRead && (
            <>
              <Row>
                <Col xs={3} className='title'>
                  Identifiant CRM
                </Col>
                <Col xs={4} className='value'>
                  {account.subscriptionId}
                </Col>
                <Col xs={2} className='title'>
                  Etat
                </Col>
                <Col xs={3} className='value'>
                  {account.active ? "activé" : "désactivé"}
                </Col>
              </Row>
              <Row>
                <Col xs={3} className='title'>
                  Connexion mCampaign : Id sous-compte Spot-Hit
                </Col>
                <Col xs={4} className='value'>
                  {account.spothitId}
                </Col>{" "}
                <Col xs={2} className='title'>
                  Plan
                </Col>
                <Col xs={3} className='value'>
                  {account.plan}
                </Col>
              </Row>
              <Row>
                <Col xs={3} className='title'>
                  Nombre de exports
                </Col>
                <Col xs={4} className='value'>
                  {account.exportFileCount}
                </Col>
              </Row>
              <Row>
                <Col xs={3} className='title'>
                  Nombre de filtres personnalisés
                </Col>
                <Col xs={4} className='value'>
                  {`${account.customFilterCount} / ${account.customFilterQuota}`}
                </Col>
                <Col xs={2} className='title'>
                  Crédits d'envois d'emails disponible (Emailer)
                </Col>
                <Col xs={3} className='value'>
                  {formatNumber(account.emailCredit)}
                </Col>
              </Row>
              <Row>
                <Col xs={3} className='title'>
                  Nombre de pré-filtres
                </Col>
                <Col xs={4} className='value'>
                  {`${account.preFilterCount} / ${account.preFilterQuota}`}
                </Col>
                <Col xs={2} className='title'>
                  Crédits d'emails nominatifs
                </Col>
                <Col xs={3} className='value'>
                  {formatNumber(account.balances["emailNominatif"])}
                </Col>
              </Row>
              <Row>
                <Col xs={3} className='title'>
                  Nombre d'utilisateurs
                </Col>
                <Col xs={4} className='value'>
                  {`${account.userCount} / ${account.userQuota}`}
                </Col>
                <Col xs={2} className='title'>
                  Crédits d'export disponible
                </Col>
                <Col xs={3} className='value'>
                  {formatNumber(account.balances["siret"])}
                </Col>
              </Row>
              <Row>
                <Col xs={3} className='title'></Col>
                <Col xs={4} className='value'></Col>
                <Col xs={2} className='title'>
                  Crédits d'envois mCampaign
                </Col>
                <Col xs={3} className='value'>
                  {account.emailCreditMCampaign}
                </Col>
              </Row>
            </>
          )}
          <Row>
            <Col></Col>
          </Row>
        </Container>
        <Container>
          <Tabs
            id='tab-account'
            onSelect={(k) =>
              setState({
                ...state,
                tabSelected: k,
              })
            }
            activeKey={state.tabSelected}>
            {canManageUsers && (
              <Tab eventKey='users' title='Utilisateurs'>
                {state.tabSelected === "users" && (
                  <AccountUsers
                    accountId={account.id}
                    account={account}
                    refreshAccount={refreshAccount}
                    canWrite={canManageUsers}
                  />
                )}
              </Tab>
            )}
            {canManageUsers && (
              <Tab eventKey='technicalusers' title='Support technique'>
                {state.tabSelected === "technicalusers" && (
                  <AccountTechnicalUsers
                    accountId={account.id}
                    account={account}
                    refreshAccount={refreshAccount}
                    canWrite={canManageUsers}
                  />
                )}
              </Tab>
            )}
            {canRead && (
              <Tab eventKey='features' title='Fonctionnalités'>
                {state.tabSelected === "features" && (
                  <AccountFeatures
                    accountId={account.id}
                    canWrite={canWrite}
                    refreshAccount={refreshAccount}
                  />
                )}
              </Tab>
            )}
            {canRead && (
              <Tab eventKey='change' title='Historique'>
                {state.tabSelected === "change" && (
                  <AccountChange accountId={account.id} />
                )}
              </Tab>
            )}
            {canRead && (
              <Tab eventKey='usage' title='Consommation'>
                {state.tabSelected === "usage" && (
                  <AccountUsage accountId={account.id} />
                )}
              </Tab>
            )}
            {canWrite && (
              <Tab eventKey='settings' title='Paramétrage'>
                {state.tabSelected === "settings" && (
                  <AccountSettings
                    account={account}
                    refreshAccount={refreshAccount}
                  />
                )}
              </Tab>
            )}
            {canWrite && (
              <Tab eventKey='credits' title='Crédits'>
                {state.tabSelected === "credits" && (
                  <AccountCredit
                    account={account}
                    refreshAccount={refreshAccount}
                  />
                )}
              </Tab>
            )}
            {canWrite && (
              <Tab eventKey='filters' title='Filtres'>
                {state.tabSelected === "filters" && (
                  <AccountFilters
                    accountId={account.id}
                    refreshAccount={refreshAccount}
                  />
                )}
              </Tab>
            )}
          </Tabs>
        </Container>
      </div>
    )
  );
}
