import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import axios from "axios";

import AccountCreateForm from "./AccountCreateForm";
import LoadingBlock from "../tools/LoadingBlock";

export default function AccountCreate() {
  const [url, setUrl] = useState(null);
  const [showLoadingBlock, setShowLoadingBlock] = useState(false);
  const [loadingCallBack, setLoadingCallBack] = useState(null);
  const [responseAccountId, setResponseAccountId] = useState(null);

  const actionSubmit = (account) => {
    setLoadingCallBack(() => {
      axios.post(`/api/admin/accounts/`, account).then((response) => {
        setResponseAccountId(response.data);
      });
    });
    setShowLoadingBlock(true);
  };

  const actionReset = () => {
    setUrl(`/`);
  };

  const accountCreateForm = (
    <AccountCreateForm
      actionSubmit={actionSubmit}
      actionReset={actionReset}
      showAddCredit={true}
      justifyCenter={true}
      accountToModify={{
        name: "",
        dataviz: 0,
        customFilterQuota: 0,
        preFilterQuota: 0,
        credit: 0,
        userQuota: 0,
        active: true,
        subscriptionId: "",
        spothitId: "",
      }}
    />
  );

  const modalLoading = showLoadingBlock ? (
    <LoadingBlock
      applyAction={loadingCallBack}
      closeModal={() => {
        setShowLoadingBlock(false);
        if (responseAccountId) {
          setUrl(`/accounts/${responseAccountId}`);
        }
      }}
    />
  ) : null;

  const redirect = url ? <Redirect to={url} /> : null;

  return (
    <Container className='accounts'>
      {modalLoading}
      {redirect}
      <h1>Création d'un compte</h1>
      {accountCreateForm}
    </Container>
  );
}
